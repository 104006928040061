import React from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Typography
} from "@mui/material";

const CheckLineDialog : React.FC<{ isShow: boolean}> = ({isShow}) =>  {
    return (
        <>
            <Dialog
                fullWidth={true}
                open={isShow}
            >
                <DialogTitle>

                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress />
                    </Box>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="caption" gutterBottom style={{fontSize: "1.2em"}}>
                            线路检测中，请稍等
                        </Typography>
                    </Box>

                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default CheckLineDialog
