import React, {useEffect, useState} from 'react';
import DownAppDialog from "./DownAppDialog";
import IosTipsDialog from "./IosTipsDialog";
import CheckLineDialog from "./CheckLineDialog";

declare function doWow(): void;
declare function doTrackAppDownload(): void;

function App() {


  const [showDownDialog, setShowDialog] = useState(false)
  const [platform, setPlatform] = useState("")
  const [showIOSDialog, setShowIOSDialog] = useState(false)
  const [showLineDialog, setShowLineDialog] = useState(false)
  let hosts :string[] = ["https://cat.maxbyte.net", "https://dog.maxjiasu.top", "https://fox.maxbyte.net", "https://3.113.226.217:23334"]
  let chn = "ggsem_bee"
  let curHost = ""



  const doCloseDialog = () =>  {
    setShowDialog(false)
  }



  const tryShowDialog = (platform: string) =>  {
     setPlatform(platform)
     setShowDialog(true)
  }

  const tryShowIOSDialog = () =>  {
    setShowIOSDialog(true)
  }


  const doConfirmIOSDialogClick = ()=>  {
    setShowIOSDialog(false)
    // 直接跳到官网
    tryGoPage("register", true)
  }


  const tryGoPage = (page: string, track: boolean) =>  {
    if(hosts.length < 1) {
      window.location.reload()
      return
    }
    if(curHost.length > 0) {
      goOnePage(curHost, page, track)
      return
    }
    setShowLineDialog(true)
    for(let i= 0; i < hosts.length; i++) {
      if(curHost.length < 1) {
        let one = hosts[i]
        fetch(one + "/server/").then((res) => res.json())
            .then((data) => {
              if (data.c === "1") { // chk ok
                if(curHost.length < 1) {
                  goOnePage(one, page, track)
                }
              }
            });
      }
    }
  }

  const goOnePage = (host: string, page: string, track: boolean) => {
    curHost = host
    setShowLineDialog(false)
    let url = host + "/" +page + "?chn=" + chn
    if(track) {
      doTrackAppDownload()
    }
    window.open(url, "_blank")
  }


  useEffect(()=> {
      doWow()
      getHosts()
  })

  const getHosts = () =>  {
     if(hosts.length < 1) {
     }
  }


  return (
      <>

        <DownAppDialog platform={platform} isShow={showDownDialog} doCloseIt={doCloseDialog}/>
        <IosTipsDialog isShow={showIOSDialog} doConfirmClick={doConfirmIOSDialogClick}/>
        <CheckLineDialog  isShow={showLineDialog} />

        <div className="page1_k">


          <div className="main_box">
            <div className="logo wow fadeInDown"
                 style={{visibility:"visible", animationName:"fadeInDown", position:"relative"}}>
              <div className="logo_tu">
                <img src="bee_static_res/images/logo1.png" alt=""/>
              </div>
              <a className="login" onClick={()=> tryGoPage("login", false)}>登录</a>
            </div>
          </div>

          <div className="main_box">
            <div className="main_sj">
              <div className="main_txt wow bounceInDown" style={{visibility:"visible", animationName:"bounceInDown"}}>
                <img src="bee_static_res/images/adtxt.png"/>
              </div>
              <div className="down_tip">优先推荐网盘下载</div>
              <div className="down_xiazai wow bounceInUp" data-wow-delay="0.2s"
                   style={{visibility:"visible", animationDelay:"0.2s", animationName:"bounceInUp"}}>
                <ul>
                  <li style={{position:"relative", zIndex:"2"}}>
                    <div className="d_btn1 downloadButton">
                      <a onClick={()=>{ tryShowDialog("android")}}
                         href="#">
                        <dl>
                          <dd>
                            <span><img src="bee_static_res/images/i_android.png"/></span>Android下载
                          </dd>
                        </dl>
                      </a>
                    </div>
                  </li>
                  <li style={{position:"relative", zIndex:"2"}}>
                    <div className="d_btn2 downloadButton">
                      <a id="iosButton01" onClick={()=>{ tryShowIOSDialog()}} >
                        <dl>
                          <dd>
                            <span><img src="bee_static_res/images/i_ios.png" alt=""/></span>IOS下载
                          </dd>
                        </dl>
                      </a>
                    </div>
                  </li>
                  <li style={{position:"relative"}}>
                    <div className="d_btn1 downloadButton">
                      <a onClick={()=>{ tryShowDialog("windows")}}
                         href="#">
                        <dl>
                          <dd>
                            <span><img src="bee_static_res/images/i_windows.png" alt=""/></span>Windows下载
                          </dd>
                        </dl>
                      </a>
                    </div>
                  </li>
                  <li style={{position:"relative"}}>
                    <div className="d_btn2 downloadButton">
                      <a id="macButton01"  onClick={()=>{ tryShowDialog("mac")}} >
                        <dl>
                          <dd>
                            <span><img src="bee_static_res/images/i_mac.png" alt=""/></span>Mac下载
                          </dd>
                        </dl>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="w_pc_tu">
                <img src="bee_static_res/images/w_pc_tu.png"/>
              </div>
            </div>
          </div>

          <div className="pt_logo wow fadeInUp" data-wow-delay="0.3s"
               style={
                 {
                   visibility: "visible",
                   animationDelay: "0.3s",
                   animationName: "fadeInUp"
                 }
               }>
            <li>
              <img src="bee_static_res/images/youtube.png" title="youtube"/>
            </li>
            <li>
              <img src="bee_static_res/images/twitter.png" title="twitter" />
            </li>
            <li>
              <img src="bee_static_res/images/facebook.png" title="facebook" />
            </li>
            <li>
              <img src="bee_static_res/images/telegram.png" title="telegram" />
            </li>
            <li>
              <img src="bee_static_res/images/Instagram.png" title="Instagram" />
            </li>
          </div>
        </div>

        <div className="hui_bg map-bg">
          <div className="main_box">
            <div className="m_bt_tilte wow fadeInDown" style={
              {
                visibility: "visible"
              }
            }>
              <h3><span>—</span> 为什么选择我们 <span>—</span></h3>
              <p>
                40+个国家/地区节点，为您提供高速、稳定的专线连接
              </p>
            </div>
          </div>

          <div className="main_box">
            <div className="jjbnt wow fadeInUp" style={
              {
                visibility: "visible"
              }
            }>
              <li className="wow fadeInUp" style={
                {
                  visibility: "visible"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i1.png" alt={""}/>
                </div>
                <dl>
                  <dt>千兆专线带宽</dt>
                  <dd>
                    依托BGP、IPLC、IEPL专线技术，独有的先进的国际互联网接入方案
                  </dd>
                </dl>
              </li>

              <li className="wow fadeInUp" style={
                {
                  visibility: "visible"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i2.png" alt={""}/>
                </div>
                <dl>
                  <dt>多平台支持</dt>
                  <dd>
                    一个账号，不限制登录设备。支持安卓、windous，MacOS、苹果、路由器等等。
                  </dd>
                </dl>
              </li>

              <li className="wow fadeInUp" style={
                {
                  visibility: "visible"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i3.png" alt={""}/>
                </div>
                <dl>
                  <dt>一键加速</dt>
                  <dd>
                    全球网站加速，游戏平台，流媒体平台，隐藏您的真实位置，保护您的个人隐私。
                  </dd>
                </dl>
              </li>

              <li className="wow fadeInUp" style={
                {
                  visibility: "hidden",
                  animationName: "none"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i4.png" alt={""}/>
                </div>
                <dl>
                  <dt>军事级加密技术</dt>
                  <dd>
                    专项加密技术和完善的安全防护体系，匿名上网，保护您的真实IP
                  </dd>
                </dl>
              </li>

              <li className="wow fadeInUp" style={
                {
                  visibility: "hidden",
                  animationName: "none"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i5.png" alt={""}/>
                </div>
                <dl>
                  <dt>持续研发与维护</dt>
                  <dd>
                    感谢广大用户对我们的支持，专业技术团队不断优化和维护产品，提供优质体验永不止步。
                  </dd>
                </dl>
              </li>

              <li className="wow fadeInUp" style={
                {
                  visibility: "hidden",
                  animationName: "none"
                }
              }>
                <div className="jjbnt_tu1">
                  <img src="bee_static_res/images/i_i6.png" alt={""}/>
                </div>
                <dl>
                  <dt>随时获取在线帮助</dt>
                  <dd>我们提供多个客服渠道，7*15小时为您提供贴心的实时在线帮助</dd>
                </dl>
              </li>
            </div>
          </div>
        </div>

        <div className="pc_xz_k">
          <div className="bai_bg">
            <div className="main_box">
              <div className="m_bt_tilte wow fadeInDown" style={
                {
                  visibility: "hidden",
                  animationName: "none"
                }
              }>
                <h3><span>—</span> 版本下载 <span>—</span></h3>
                <p>请选择合适版本点击下载</p>
              </div>
            </div>
            <div className="main_box">
              <div className="pc_downlist">
                <li className="wow fadeInUp" data-wow-delay="0.3s" style={
                  {
                    visibility: "hidden",
                    animationDelay: "0.3s",
                    animationName: "none"
                  }
                }>
                  <a onClick={()=>{ tryShowDialog("android")}} className="downloadButton" data-agl-cvt="6">
                    <dl>
                      <dt>
                        <img src="bee_static_res/images/i_android.png" alt=""/>
                      </dt>
                      <dd>Android下载</dd>
                    </dl>
                  </a>
                </li>

                <li className="wow fadeInUp" data-wow-delay="0.4s" style={
                  {
                    visibility: "hidden",
                    animationDelay: "0.4s",
                    animationName: "none"
                  }
                }>
                  <a id="iosButton02" onClick={()=>{ tryShowIOSDialog()}} className="downloadButton"
                     data-agl-cvt="6">
                    <dl>
                      <dt>
                        <img src="bee_static_res/images/i_ios.png" />
                      </dt>
                      <dd>IOS下载</dd>
                    </dl>
                  </a>
                </li>

                <li className="wow fadeInUp" data-wow-delay="0.1s" style={
                  {
                    visibility: "hidden",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }
                }>
                  <a onClick={()=>{ tryShowDialog("windows")}}
                     data-agl-cvt="6">
                    <dl>
                      <dt>
                        <img src="bee_static_res/images/i_windows.png" alt={""}/>
                      </dt>
                      <dd>Windows下载</dd>
                    </dl>
                  </a>
                </li>

                <li className="wow fadeInUp" data-wow-delay="0.2s" style={
                  {
                    visibility: "hidden",
                    animationDelay: "0.2s",
                    animationName: "none"
                  }
                }
              >
                  <a id="macButton02" onClick={()=>{ tryShowDialog("mac")}}  data-agl-cvt="6">
                    <dl>
                      <dt>
                        <img src="bee_static_res/images/i_mac.png" alt={""}/>
                      </dt>
                      <dd>Mac下载</dd>
                    </dl>
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>


        <div className="down_box_bg">
          <div className="main_box">
            <div className="down_nav">
              <span><a href="terms.html" target={"_blank"}>用户权限</a></span>
              <span><a href="privacy.html" target={"_blank"}>隐私协议</a></span>
            </div>
            <div className="down_cop">
              Copyright 2024 MAX加速器<br/>

            </div>
          </div>
        </div>


      </>

  );
}

export default App;
