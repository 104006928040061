import React, { useEffect, useState } from "react";
import {
    Box, Button,
    Dialog, DialogContent,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText, TextField,
    Typography
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import ShareIcon from '@mui/icons-material/Share';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

declare function doTrackAppDownload(): void;

const DownAppDialog : React.FC<{platform: string, isShow: boolean, doCloseIt: ()=>void }> = ({platform, isShow, doCloseIt}) =>  {


    const [netTips, setNetTips] = useState("")



    const getNetShareA = () => {
        let mp = new Map<string, string>
        mp.set("url", "https://share.feijipan.com/s/0mA66cmV")
        mp.set("pwd", "")
        return mp
    }


    const getNetShareB = () => {
        let mp = new Map<string, string>
        mp.set("url", "https://wwm.lanzoue.com/b00moawaje")
        mp.set("pwd", "60vt")
        return mp
    }

    const doDownWeb = () =>  {
        let url = ""
        if(platform == "android") {
            url =  "https://download.maxgogo.win/files/bee/max.apk"
        }else if(platform == "mac") {
            url =  "https://download.maxgogo.win/files/bee/max.pkg"
        }else if(platform == "windows") {
            url =  "https://download.maxgogo.win/files/bee/max.zip"
        } else if(platform == "ios") {
            url =  "https://baidu.com/android/ios"
        }
        doOpenDownUrl(url)
    }

    useEffect(()=> {
        if(isShow) {
            let pwd = getNetShareB().get("pwd") + ""
            if(pwd.length > 0) {
                tryCopyPwd(pwd)
            }
        }
    })


    const tryCopyPwd = (pwd: string) =>  {
        navigator.clipboard.writeText(pwd).then(() => {
            setNetTips("已自动复制密码" + pwd + "，跳转粘贴即可")
        }).catch(() => {
            setNetTips("网盘密码：" + pwd)
        });
    }


    const getName = () =>  {
        if(platform == "android") {
            return "安卓"
        }else if(platform == "mac") {
            return "Mac"
        }else if(platform == "windows") {
            return "Win"
        } else if(platform == "ios") {
            return "iOS"
        }
        return ""
    }


    const doDownNetDisk = (one: Map<string, string>) =>  {
        let url = one.get("url") + ""
        let pwd = one.get("pwd") + ""
        if(pwd.length > 0) {
            tryCopyPwd(pwd)
        }
        doOpenDownUrl(url)
    }

    const doOpenDownUrl = (url: string) => {
        doTrackAppDownload()
        window.open(url, "_blank")
    }


    return (
        <>
            <Dialog
                fullWidth={true}
                onClose={() => doCloseIt() }
                open={isShow}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">
                    下载方式
                    <Typography variant="caption" gutterBottom>
                        （{getName()}下载太慢，就换另一个）
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => doCloseIt() }
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[800],
                    }}
                >
                    <CancelIcon />
                </IconButton>
                <DialogContent style={{paddingBottom:20, paddingTop:0}}>



                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={80}>
                        <Button variant={"contained"} size={"large"} onClick={()=> { doDownNetDisk(getNetShareA()) }} fullWidth={true}  color={"warning"}  startIcon={<ShareIcon/>} style={{textTransform: 'none'}}>
                            网盘下载1（密码：无）
                        </Button>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={60}>
                        <Button variant={"contained"} size={"large"} onClick={()=> { doDownNetDisk(getNetShareB()) }} fullWidth={true}  color={"primary"}  startIcon={<ShareIcon/>} style={{textTransform: 'none'}}>
                            网盘下载2（密码：{getNetShareB().get("pwd")}）
                        </Button>
                    </Box>


                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={20}>
                        <Typography variant={"caption"} color={"secondary"} noWrap>
                        {netTips}
                        </Typography>
                    </Box>


                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={80}>
                        <Button variant={"contained"} size={"large"} onClick={()=> { doDownWeb() }} fullWidth={true} color={"info"} startIcon={<SaveAltIcon/>}>
                            官网下载
                        </Button>
                    </Box>

                </DialogContent>
            </Dialog>
        </>
    )
}


export default DownAppDialog
