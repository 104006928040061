import React from "react";
import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText, Modal,
    Typography
} from "@mui/material";

const IosTipsDialog  : React.FC<{ isShow: boolean, doConfirmClick: ()=>void}> = ({isShow, doConfirmClick}) =>  {



    return (
        <>
            <Dialog
                fullWidth={true}
                open={isShow}
            >
                <DialogTitle>
                    温馨提示：
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontSize: "1.2em"}}>
                        iOS需先注册账号，才能下载哦~
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {doConfirmClick()}} autoFocus>
                        好的，马上注册
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default IosTipsDialog
